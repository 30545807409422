<template>
    <div>
        <div id="raycaster">
            <div id="game-container" ref="screenContainer">
                <canvas id="background-layer" width="1074" height="608"></canvas>
                <canvas id="game-layer" width="1074" height="608"></canvas>
                <div id="ui-layer">
                <transition name="fade">
                    <img v-if="showTitle" class="title-image fade" src="@/assets/images/raycasterWalls/pharaohTextWings.svg">
                </transition>
                <div id="cta-text"> {{ displayText }}</div>
                </div>
                <FullScreenButton
                    :element="$refs.screenContainer"
                />
            </div>
            <div class="play-overlay-container">
                <transition name="fade">
                    <div v-if="!isHidden" @click="play" class="play-overlay">
                        <img class="overlay-image" :src="require('@/assets/images/tombOverlay.png')">
                        <img class="arrow" :src="require('@/assets/images/arrowLarge.svg')">
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import RaycasterWalls, {stopMap} from "../gameFiles/raycasterWalls1_1";
import levelData from "../gameFiles/gameData1_1";
import FullScreenButton from "@/components/page_elements/FullScreenButton";
export default {
    name: 'RaycasterGame1_1',
        components: {
        FullScreenButton,
    },
    data() {
        return {
            isHidden: false,
            displayText: 'Press Space',
            showTitle: false,
        }
    },
    methods: {
        play() {
            this.isHidden = true;
            this.showTitle = true;
            RaycasterWalls(
                levelData.map, 
                this.decodeColours(levelData.textures),
                levelData.background,
                this.uiChanges,
            );
        },
        decodeColours(textureArray) {
            textureArray.forEach( texture => {
                texture.colours = JSON.parse(atob(texture.colours));
            })
            return textureArray;
        },
        uiChanges(setting = {}) {
            if (setting.text) this.displayText = setting.text.newText;
            if (setting.title !== undefined) this.showTitle = setting.title;
            if (setting.endGame) RaycasterWalls(levelData, this.mapTextures, this.uiChanges);
        },
    },
    beforeDestroy() {
        stopMap();
    }
}
</script>

<style lang="scss" scoped>
    canvas { 
        position: absolute;
        top: 0;
        left: 0;
        @media (display-mode: fullscreen) {
            width: 100%;
            height: 100%;
        }
    }

    #raycaster {
        position: relative;
        width: 1074px;
        height: 608px;
    }
    
    .game-container {
        position: relative;
    }

    .play-overlay-container {
        position: relative;
    }

    .play-overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;

        &.hidden {
            display: none;
        }
    }

    .arrow {
        position: absolute;
    }

    #ui-layer {
        position: absolute;
        width: 1074px;
        height: 608px;
        display: flex;
        justify-content: center;

        @media (display-mode: fullscreen) {
            width: 100%;
            height: 100%;
        }
    }

    .title-image {
        position: absolute;
        left: 15%;
        z-index: 10;
        width: 70%;
        margin-top: 30px;
    }

    .overlay-image {
        border-radius: 30px;
    }

    #cta-text {
        position: relative;
        top: 80%;
        font-family: system-ui;
        font-size: 30px;
        color: rgb(29, 27, 27);
        font-weight: bold;
        text-shadow: rgb(243, 188, 86) 1px 0 10px;

        @media (display-mode: fullscreen) {
            font-size: 60px;
        }
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .8s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>