function draw() {
    ctx.clearRect(0, 0, canvasWidth, canvasHeight);

    getFps();
    playerActions(playerData);
    getAllLines(playerData.direction);
    drawLines3d();
    if (!running) return;
    requestAnimationFrame(draw);
}

function getFps() {
    thisLoop = new Date();
    if (thisLoop - lastLoop > 500) {
        fps = fpsTick;
        delta = 30 / fps;
        lastLoop = thisLoop;
        fpsTick = 0;
    }
    fpsTick += 1;
}

function getDirectionValues(direction) {
    return {
        xChange: Math.cos(direction),
        yChange: Math.sin(direction),
    }
}

function checkCondtions(conditions) {
    if (!conditions) return true;
    let getItem;
    let cond;
    let conditionsMet = true;
    conditions.forEach(condition => {
        cond = condition.metaData;
        getItem = inventory.find(item => item.id === cond.checkItem)
        if (!eval(`${getItem.value} ${cond.conditionCheck} ${cond.checkValue}`)) conditionsMet = false;
    });
    return conditionsMet;
}

function actionEvents(eventData) {
    let currentEvent = mapEvents[eventData.wallDir][eventData.wallIndex];
    if (!checkCondtions(currentEvent.conditions)) return;
    eventData.actions.forEach(action =>{
        if (action.type === 'change') {
            let actionData = action.metaData;
            if (actionData.wallDirection === 'vert') vertWallList[actionData.wallNum] = actionData.newWallIdx;
            if (actionData.wallDirection  === 'horz') horzWallList[actionData.wallNum] = actionData.newWallIdx;
        }
        if (action.type === 'exchangeItem') {
            let actionData = action.metaData;
            let item = inventory.find(item => item.id === actionData.itemId)
            item.value += actionData.valueChange;
        }
    })
    currentEvent.available = false;
    eventAvailable = resetEventAvailable();
    uiSettings({text: {newText: ''} });
}

function keyPlayers(p) {
    if (controlsEnabled) {
        if (keyup) p.speed += (0.12 * delta);
        if (keydown) p.speed -= (0.12  * delta);
        if (keyleft) p.turnSpeed += (1 * delta);
        if (keyright) p.turnSpeed -= (1 * delta);
        if (keyW && eventAvailable.inRange) actionEvents(eventAvailable);
        p.turnSpeed *= 0.65;
        p.speed *= 0.83;
        p.direction += p.turnSpeed;
        if (p.direction > 360) p.direction = p.direction % 360;
        if (p.direction < 0) p.direction = 360 - p.direction;
        if (Math.abs(p.speed) < 0.01) p.speed = 0;
        if (Math.abs(p.turnSpeed) < 0.01) p.turnSpeed = 0;
    }
    if (!controlsEnabled && keyW) {
        controlsEnabled = true;
        uiSettings({
            title:  false,
            text: {
                newText: '',
            }
        })
    }
}

function playerActions() {
    keyPlayers(playerData);
    const {xChange, yChange} = getDirectionValues(convertToRadians(playerData.direction));
    let moveX =  xChange * playerData.speed;
    let moveY =  yChange * playerData.speed;
    let xDir = moveX > 0 ? 1 : -1;
    let yDir = moveY < 0 ? 1 : -1;
    let collisionPoints = []
    playerData.xPos += moveX;
    collisionPoints = setCollisionPoints(playerData);
    checkXCollision(collisionPoints, 0, 2, xDir);
    checkXCollision(collisionPoints, 1, 3, xDir);
    playerData.yPos -= moveY;
    collisionPoints = setCollisionPoints(playerData);
    checkYCollision(collisionPoints, 0, 1, yDir);
    checkYCollision(collisionPoints, 2, 3, yDir);
}

function setCollisionPoints(object) {
    let collisionPoints = [];
    collisionPoints.push({xPos: object.xPos + object.width, yPos: object.yPos - object.height})//TR
    collisionPoints.push({xPos: object.xPos + object.width, yPos: object.yPos + object.height})//BR
    collisionPoints.push({xPos: object.xPos - object.width, yPos: object.yPos - object.height})//TL
    collisionPoints.push({xPos: object.xPos - object.width, yPos: object.yPos + object.height})//BL
    return collisionPoints;
}

function checkXCollision(collisionPoints, point1, point2, dirMod) {
    let checkPoint1 = collisionPoints[point1];
    let checkPoint2 = collisionPoints[point2];
    if (
        Math.floor((checkPoint1.xPos) / 16) * 16
        !== Math.floor((checkPoint2.xPos) / 16) * 16
    ) {
        const wallX = Math.floor(checkPoint1.xPos / 16) * 16
        let wallNumber = checkVertWalls(wallX, checkPoint1.yPos);
        if (vertWallList[wallNumber] > 0) {
            playerData.xPos = wallX - ((playerData.width + 0.0001) * dirMod);
        }
    }
}

function checkYCollision(collisionPoints, point1, point2, dirMod) {
    let checkPoint1 = collisionPoints[point1];
    let checkPoint2 = collisionPoints[point2];
    if (
        Math.floor((checkPoint1.yPos) / 16) * 16
        !== Math.floor((checkPoint2.yPos) / 16) * 16
    ) {
        const wallY = Math.floor(checkPoint2.yPos / 16) * 16
        let wallNumber = checkHorzWalls(checkPoint2.xPos, wallY);
        if (horzWallList[wallNumber] > 0) {
            playerData.yPos = wallY - ((playerData.height + 0.0001) * dirMod);
        }
    }
}

function getAllLines() {
    lineEndList = [];
    textureOffsetList = [];
    textureNumList = [];
    actualDistance = [];
    let midLine = false;
    for (let i = 0; i < scanLines; i++) {
        if (i === scanLines / 2) midLine = true;
        getSingleLineEnd(rayAngleLookup[i], midLine);
        midLine = false;
    }
}

function getSingleLineEnd(lineDir, midLine = false) {
    const xPos = playerData.xPos;
    const yPos = playerData.yPos;
    const radianDir = convertToRadians(playerData.direction) + lineDir;
    const {xChange, yChange} = getDirectionValues(radianDir);
    const xStep = xChange < 0  ? -1 : 1;
    const yStep = yChange < 0  ? -1 : 1;
    const yToEdge = yChange < 0 ? 16 - (yPos % 16) : yPos % 16;
    const xToEdge = xChange > 0  ? 16 - (xPos % 16) : xPos % 16;
    let checkyPos = yToEdge * yStep;
    let checkxPos = xToEdge * xStep;
    let solid = 0;
    let colXpos;
    let rowXpos;
    let colYpos;
    let rowYpos;
    const stepAngle = Math.tan(radianDir);
    let rowDistance = getDistance(0, checkyPos, 0, checkyPos / stepAngle);
    let rowStep = getDistance(0, 16, 0, 16 / stepAngle);
    let colDistance = getDistance(0, checkxPos, 0, checkxPos * stepAngle);
    let colStep = getDistance(0, 16, 0, 16 * stepAngle);

    while (solid === 0) {
        if (rowDistance < colDistance) {
            rowXpos = xPos + (checkyPos / stepAngle); 
            rowYpos = yPos - (checkyPos);
            solid = horzWallList[Math.floor(checkHorzWalls(rowXpos, rowYpos))];
            if (solid === 0) {
                checkyPos += 16 * yStep;
                rowDistance += rowStep;
            } 
        } else {
            colXpos = xPos + (checkxPos); 
            colYpos = yPos - (checkxPos * stepAngle);
            solid = vertWallList[Math.floor(checkVertWalls(colXpos, colYpos))]
            if (solid === 0) {
                checkxPos += 16 * xStep;
                colDistance += colStep;
            }
        }
    }

    if (rowDistance < colDistance) {
        actualDistance.push(Math.round(rowDistance * 100) / 100);
        lineEndList.push(Math.round((Math.cos(lineDir) * rowDistance) * 10) / 10);
        textureOffsetList.push(Math.floor(((rowXpos /  16) % 1) * textureSize));
        textureNumList.push(solid);
        if (midLine) checkEventAvailable('horz', Math.floor(checkHorzWalls(rowXpos, rowYpos)));
    } else {
        actualDistance.push(Math.round(colDistance * 100) / 100);
        lineEndList.push(Math.round((Math.cos(lineDir) * colDistance) * 10) / 10);
        textureOffsetList.push(Math.floor(((colYpos / 16) % 1) * textureSize));
        textureNumList.push(solid);
        if (midLine) checkEventAvailable('vert', Math.floor(checkVertWalls(colXpos, colYpos)));
    }
}

function checkEventAvailable(direction, wallNum) {
    if (!controlsEnabled) return;
    const itemNum = lineEndList.length - 1;
    if (mapEventsIndex[direction].includes(wallNum) &&
        (lineEndList[itemNum] / 16) < 1 ) {
        const wallIndex =  mapEventsIndex[direction].indexOf(wallNum);
        const eventData = mapEvents[direction][wallIndex];
        if (!eventData.available) return;
        if (!eventAvailable.inRange) {
            uiSettings({text: {newText: eventData.text.cta} })
            eventAvailable.messageShowing = true;
        }
        eventAvailable.wallDir = direction;
        eventAvailable.wallIndex = wallIndex;
        eventAvailable.inRange = true;
        eventAvailable.actions = eventData.actions;
        return;
    }
    if (eventAvailable.messageShowing) {
        uiSettings({text: {newText: ''} });
        eventAvailable.messageShowing = false;
    }
    eventAvailable.inRange = false;
}

function checkVertWalls(xPos, yPos) {
    const xValue = Math.round(xPos) / 16;
    const yValue = Math.floor(yPos / 16);
    return (xValue * (mapRows)) + yValue;
}

function checkHorzWalls(xPos, yPos) {
    const xValue = Math.floor(xPos / 16);
    const yValue = Math.round(yPos) / 16;
    return (yValue * (mapColumns)) + xValue;
}

function convertToRadians(direction) {
    return direction * (Math.PI / 180);
}

function getDistance(x1,x2,y1,y2) {
    const xDif = x1 - x2;
    const yDif = y1 - y2;
    return Math.sqrt((xDif * xDif) + (yDif * yDif));
}

function drawLines3d() {
    let lineXpos = resolution / 2;
    let listLength = lineEndList.length;
    for (let i = 0; i < listLength; i++) {
        draw3dLine(i, lineXpos);
        lineXpos += resolution;
    }
}

function brightnessCalc(distance) {
    let full = 16 * 0.1;
    let noLight = 16 * 5;
    if (distance < full) return 0.95;
    if (distance > noLight) return 0.12;
    const shadeValue = 0.95 - (
        Math.floor(((distance - full) / (noLight - full) ) * 1000) / 1000
    );
    if (shadeValue > 0.95) return 0.95;
    if (shadeValue < 0.12) return 0.12;
    return shadeValue;
}

function getScaledSize(value) {
    switch(true) {
        case (value < 4):
            return 4;
        case (value < 7):
            return 8;
        case (value < 16):
            return 16;
        case (value < 24):
            return 32
        case (value < 64):
            return 64
        default:
            return textureSize;
    }
}

function draw3dLine(i, lineX) {
    const lineDistance = lineEndList[i];
    const textureList = allTexturesList[(textureNumList[i] - 1)];
    let textureOffset = textureOffsetList[i];
    const height = Math.floor(wallHeight * (DV / lineDistance) * 10) / 10;
    const brightness = brightnessCalc(actualDistance[i]);
    let lineYpos = (canvasHeight / 2) - height;
    let drawColour;
    let newColour;
    let ti = 0;
    const scaledTextureSize = getScaledSize(Math.floor(height / resolution));
    const skip = Math.floor(textureSize / scaledTextureSize);
    let drawHeight = (((height * 2) - resolution) / textureSize) * skip;
    const lineVMove = textureSize * skip

    while (lineYpos + drawHeight < 0) {
        lineYpos += drawHeight;
        textureOffset += textureSize;
        ti++;
    }
    ctx.moveTo(lineX, lineYpos);
    newColour = textureColours[textureList[textureOffset]];
    while (ti < scaledTextureSize) {
        drawColour = textureColours[textureList[textureOffset]];

        if( newColour != drawColour) {
            drawLineSection(lineX,Math.floor(lineYpos), setRgbString(newColour, brightness))
            newColour = drawColour;
        }
        lineYpos += drawHeight;
        textureOffset += lineVMove;
        if(lineYpos > canvasHeight) {
            drawLineSection(lineX, canvasHeight, setRgbString(newColour, brightness));
            return;
        }
        ti++
    }
    drawLineSection(lineX,lineYpos, setRgbString(drawColour, brightness));
}

function drawLineSection(xPos,yPos,colour) {
    const yPosMod = Math.ceil(yPos)
    ctx.strokeStyle = colour;
    ctx.lineTo(xPos, yPosMod);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(xPos, yPosMod);
}

function drawBackground(height, width, floor, ceiling , black = 0.1, full = 0.1) {
    let backgroundCanvas = document.getElementById('background-layer');
    let backgroundCtx = backgroundCanvas.getContext('2d', { alpha: false });
    backgroundCanvas.width = width;
    backgroundCanvas.height = height;

    const linesInSection = height / 2;
    const blackArea = black;
    const fullArea = full;
    const blackSection = Math.floor(linesInSection * blackArea);
    const fullSection = Math.floor(linesInSection * fullArea);
    const fadeSection = Math.floor(linesInSection - (blackSection + fullSection));
    const linePercentage =  1 / fadeSection;

    backgroundCtx.lineWidth = 3;
    backgroundCtx.fillStyle = setRgbString(ceiling);
    backgroundCtx.fillRect(0, 0, width, fullSection );
    for (let i = 0; i < fadeSection; i++) {
        backgroundCtx.beginPath();
        backgroundCtx.strokeStyle = setRgbString(ceiling, (fadeSection - i) * linePercentage);
        backgroundCtx.moveTo(0, fullSection + i);
        backgroundCtx.lineTo(width, fullSection + i);
        backgroundCtx.stroke();
    }
    backgroundCtx.fillStyle = 'black';
    backgroundCtx.fillRect(0, fullSection + fadeSection, width, blackSection );

    backgroundCtx.fillStyle = setRgbString(floor);
    backgroundCtx.fillRect(0, height, width, -fullSection );
    for (let i = 0; i < fadeSection; i++) {
        backgroundCtx.strokeStyle = setRgbString(floor, (fadeSection - i) * linePercentage);
        backgroundCtx.beginPath();
        backgroundCtx.moveTo(0, (height - fullSection) - i);
        backgroundCtx.lineTo(width, (height - fullSection) - i);
        backgroundCtx.stroke();
    }

    backgroundCtx.fillStyle = 'black';
    backgroundCtx.fillRect(0, linesInSection, width, blackSection);
}


function decodeTexture(textureData, colourMod = 0) {
    let decodedData = [];
    let dataRun = 0;
    let nextPixel = textureData[dataRun];
    while (dataRun < textureData.length) {
        nextPixel = textureData[dataRun];
        for (let i = 0; i < textureData[dataRun + 1]; i++) {
            decodedData.push(nextPixel + colourMod);
        }
    dataRun += 2;
    }
    return decodedData;
}

function decodeLevel(levelData) {
    let mapUpload = [];
    let tileRun = 0;
    let nextTile;
    let i;
    while (tileRun < levelData.length) {
        nextTile = levelData[tileRun];
        for (i = 0; i < levelData[tileRun + 1]; i++) {
            mapUpload.push(nextTile);
        }
    tileRun += 2;
    }
    return mapUpload;
}

function setRgbString(data, mod = 1) {
    const red = Math.floor(data.red * mod);
    const green = Math.floor(data.green * mod);
    const blue = Math.floor(data.blue * mod);
    return `rgb(${red},${green},${blue})`;
}

function setResolution(newResolution) {
    resolution = newResolution;
    ctx.lineWidth = resolution;
    scanLines = Math.floor(canvasWidth / resolution);
    rayAngleLookup = createRayAngleLookup();
}

function setDV() {
    return (canvasWidth / 2) / Math.tan(convertToRadians(FOV / 2));
}

function setStartCoords(tile, mapWidth) {
    playerData.xPos = ((tile % mapWidth) * 16) + 16 / 2;
    playerData.yPos = (Math.floor(tile / mapWidth) * 16) + 16 / 2;
}

function resetEventAvailable() {
    return {
        inRange: false,
        actions: [],
        wallDir: '',
        wallIndex: undefined,
        messageShowing: false,
        text: {},
    }
}

let ctx;
let canvas;
let running = false;
let canvasWidth = 1074;
let canvasHeight = 608;
let vertWallList;
let horzWallList;
let mapColumns;
let mapRows;
let keyup = false;
let keydown = false;
let keyright = false;
let keyleft = false;
let keyW = false;
let lineEndList = [];
let actualDistance = [];
let textureOffsetList = [];
let FOV = 80;
let resolution = 3;
let DV = setDV();
let scanLines = Math.floor(canvasWidth / resolution);
let textureSize = 32;
let textureColours = [];
let allTexturesList = [];
let textureNumList = [];
let fps;
let fpsTick = 30;
let lastLoop = 500;
let thisLoop = 0;
let delta = 1;
let eventAvailable = resetEventAvailable();
let mapEvents = {
    vert: [],
    horz: [],
};
let wallHeight = 8;
let mapEventsIndex = {
    vert: [],
    horz: [],
}
let playerData = {
    xPos: 50,
    yPos: 50,
    width: 3,
    height: 3,
    direction: 290,
    turnSpeed: 0,
    speed: 0,
}
let rayAngleLookup = [];
let inventory = [];
let uiSettings;
let controlsEnabled;
let backgroundData = {};

function keyHandler(event) {
    if (["ArrowUp","ArrowDown","ArrowLeft","ArrowRight","Space"].indexOf(event.code) > -1) {
        event.preventDefault();
    }
    if (event.key === "ArrowUp") keyup = event.type === 'keydown';
    if (event.key === "ArrowDown") keydown = event.type === 'keydown';
    if (event.key === "ArrowRight") keyright = event.type === 'keydown';
    if (event.key === "ArrowLeft") keyleft = event.type === 'keydown';
    if (event.key === " ") keyW = event.type === 'keydown';
}

async function setTextures(texturesImported) {
    return texturesImported.map((textureData) => {
        let mapTextures = decodeTexture(
            textureData.encodedTexture,
            textureColours.length
        )
        textureData.colours.forEach(async (colour) =>  textureColours.push(colour));
        return mapTextures;
    })
}

function setEventData(eventsData) {
    if (!eventsData) return;
    let modedData = eventsData.map(event => {
        return {
            ...event,
            available: true,
        }
    })
    mapEvents.vert = modedData.filter(event => event.metaData.wallDirection === 'vert' );
    mapEvents.horz = modedData.filter(event => event.metaData.wallDirection === 'horz' );
    mapEventsIndex.vert = mapEvents.vert.map(event => event.metaData.wallNum);
    mapEventsIndex.horz = mapEvents.horz.map(event => event.metaData.wallNum);
}

function setItemData(itemData) {
    inventory = itemData.map(item => {
        return {
            ...item,
            value: 0,
        }
    })
}

function stopMap() {
    running = false;
    console.log('stopped');
    document.removeEventListener('keydown', keyHandler);
    document.removeEventListener('keyup', keyHandler);
}

async function setUpMap(levelImport, textureImport, background, uiChanges) {
    controlsEnabled = false
    textureSize = Number(levelImport.textureSize);
    uiSettings = uiChanges;
    backgroundData = {
        floor: JSON.parse(atob(background.floorSettings)),
        ceiling: JSON.parse(atob(background.ceilingSettings)),
        fade: JSON.parse(atob(background.fadeSettings)),
    };
    allTexturesList = await setTextures(textureImport);
    vertWallList = decodeLevel(levelImport.vertWallData, 'vert');
    horzWallList = decodeLevel(levelImport.horzWallData, 'horz');
    mapColumns = levelImport.verticalLines - 1;
    mapRows = levelImport.horizontalLines - 1;
    setStartCoords(levelImport.startTile, mapColumns);
    setItemData(JSON.parse(atob(levelImport.items)));
    setEventData(JSON.parse(atob(levelImport.mapEvents)));
    playerData.direction = 260;
    const {floor, ceiling, fade} = backgroundData;
    drawBackground(
        canvasHeight,
        canvasWidth,
        floor.colour,
        ceiling.colour,
        fade.blackArea,
        fade.fullArea,
    );
    if (!running) startMap();
}

function createRayAngleLookup() {
    let lookupArray = [];
    let xPlace;
    let startXrayPositiion = (resolution / 2) - (canvasWidth / 2);
    for (let i = scanLines; i > 0; i--) {
        xPlace = startXrayPositiion + (i * resolution );
        lookupArray.push(Math.atan(xPlace / DV));
    }
    return lookupArray;
}

function startMap() {
    running = true;
    canvas = document.getElementById('game-layer');
    ctx = canvas.getContext('2d');
    document.addEventListener('keydown', keyHandler, false);
    document.addEventListener('keyup', keyHandler, false);
    setResolution(resolution);
    draw();
}

export default setUpMap;
export {stopMap};