<template>
    
<div>
  <ProjectPageTemplate
    projectTitle="BASKETBALL SCORESHEET"
    :imageSource="require('@/assets/images/projectLinks/bballSheet.png')"
    shortDescription="Project is under a subdomain of this site, follow the link above to view. Created with the purpose of being able to log the events of a basketball game digitally and then produce a scoresheet of game events in the same format as a standard written version. Also use the data logged for the game to create a boxscore with player and team stats, which is also printable. This was a  large scale project which extensively used Vue components and functionality."
    longDescription="Having been involved with basketball, I wanted the challenge of creating a way to digitally score games that was intuitive and easy to use,  but would still format the data from the games to produce a full score sheet in the style traditional hand written one. This could either be printed as a paper copy or made into a pdf file. An additional advantage of storing the data this way allows for stats to be created in the form of a boxscore sheet. This can also be generated and has the option to be printed or made into a pdf.<br>
                    <br>The game details page allows for entry of teams, players, coaches and officials data along with data for the game such as date, time and venue. Starting the game the logging screen can be used for points, fouls, foul shooting  and time outs to be recorded at the game minute and quarter they happened.<br>
                    <br>Using Pinia for the first time allowed the game data to be stored and imported to all components in the project, along with storing functions that can be used.<br>
                    <br>I feel the project has a large scope to be added to, including storing the game data locally and eventually online to allow for results and stats to be referenced by others.<br>"
    :importedLinkData="techLinksData"
  >
    <template v-slot:main-content>
      <div id="content-container">
            <CarouselVue
              :imageList="images"
              :carouselData="carouselSettings"
            />
            <a class="score-sheet-link" href="https://bball-scoresheet.ben-smith-development.com"> {{'Click for Score Sheet >>>' }}</a>
      </div>
    </template>
  </ProjectPageTemplate>
</div>

</template>

<script>
import ProjectPageTemplate from "@/components/ProjectPageTemplate";
import CarouselVue from  "@/components/CarouselVueComp"
import techLinks from '@/data/techLinks.js'
export default {
    name: 'BBallSheet',
    components: {
        CarouselVue,
        ProjectPageTemplate,
    },
    data() {
      return {
        techLinksData: [
          techLinks.html, 
          techLinks.javascript,
          techLinks.vue3,
          techLinks.pinia,
        ],
        carouselSettings: {
          folder: 'images/scoreSheet'
        },
        images: [
            {
              imagePath: 'home.png',
              descriptionText: 'Home Page: Start the game logging',
            },
            {
              imagePath: 'details.png',
              descriptionText: 'Details Page: Enter and edit details for the game',
            },
            {
              imagePath: 'logging.png',
              descriptionText: 'Logging Page: Log game events',
            },
            {
              imagePath: 'end.png',
              descriptionText: 'Game End Page: View created sheets once game is completed',
            },
            {
              imagePath: 'box.png',
              descriptionText: 'Box Score: View and print team and player stats',
            },
            {
              imagePath: 'score.png',
              descriptionText: 'Score Sheet: View and print team rendered score sheet',
            },
        ]
    }
  },
}

</script>

<style lang="scss" scoped>

#content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.score-sheet-link {
    background: rgb(255, 220, 20);
    margin: 15px 0px;
    border: 3px solid black;
    width: 80%;
    text-align: center;
    border-radius: 6px;
    font-size: 30px;
    text-transform: uppercase;
    font-weight: bold;

    &:hover {
      background-color: #000000;
      color: #fff;
    }
}

</style>